import {ReactElement} from 'react';
import styled from 'styled-components';
import {BorderRadius, GridWrapper, imageLoaderResponsive, mediaQueries} from '@theme/lib';
import Image from 'next/image';
import {useMobile} from "@lib/hooks/useMobile";
import { getLanguage } from '@/lib/config';

const ImagesWrapper = styled(GridWrapper)`
  img {
    ${props => BorderRadius(props.theme.config.imageBorderRadius.mobile)};
    overflow: hidden;
    width: 100%;

    ${mediaQueries.lg} {
      ${props => BorderRadius(props.theme.config.imageBorderRadius.desktop)};
    }
  }

  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(5, [col-start] 1fr);
    gap: 1rem;
    ${mediaQueries.lg} {
      grid-template-columns: repeat(10, [col-start] 1fr);
      gap: 2rem;
    }
  }

  .images-layout {

    img {
      object-fit: cover;
    }

    &__left {
      .image-left-block {
        grid-column: 1 / span 2;

        ${mediaQueries.lg} {
          grid-column: 1 / span 4;
        }
      }

      .image-right-block {
        grid-column: 3 / span 3;

        ${mediaQueries.lg} {
          grid-column: 5 / span 6;
        }
      }
    }

    &__right {
      .image-left-block {
        grid-column: 1 / span 3;

        ${mediaQueries.lg} {
          grid-column: 1 / span 6;
        }
      }

      .image-right-block {
        grid-column: 4 / span 2;

        ${mediaQueries.lg} {
          grid-column: 7 / span 4;
        }
      }
    }
  }

  footer > small.copyright {
    display: block;
    color: ${(props) => props.theme.colors.caption};
    margin-top: 0.375rem;
    margin-bottom: -0.375rem;
  }
  .images-caption {
    color: ${(props) => props.theme.colors.caption};
    margin-top: 0.375rem;

    font-size: 0.75rem;
    line-height: 0.9rem;

    ${mediaQueries.lg} {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.05rem;
    }
  }
`;

interface ImagesProps {
  leftImage: {
    copyright?: string|null;
    altText: string|null;
    url: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  }
  rightImage: {
    copyright?: string|null;
    altText: string|null;
    url: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  }
  caption?: string|null
  imageLayout: string
}

export default function Images({ leftImage, rightImage, caption, imageLayout }: ImagesProps): ReactElement {
  const isMobile = useMobile();
  const language = getLanguage();
  const leftWidth = (imageLayout === 'left' ? (isMobile ? 320 : 592) : (isMobile ? 488 : 904));
  const rightWidth = (imageLayout !== 'left' ? (isMobile ? 320 : 592) : (isMobile ? 488 : 904));
  const height = (isMobile ? 302 : 560)
  return <ImagesWrapper alignment={"full-width"}>
    <div className={`images-layout images-layout__${imageLayout}`}>
      <div className="images-wrapper">
        <div className="image-left-block">
          <Image
            src={leftImage.url}
            alt={leftImage.altText || ''}
            layout="intrinsic"
            width={leftWidth}
            height={height}
            loader={imageLoaderResponsive(leftWidth, height)}
          />
          {leftImage.copyright &&
            <footer>
              <small className="copyright">
                &copy; {leftImage.copyright.replace('&copy;', '')}
              </small>
            </footer>}

        </div>
        <div className="image-right-block">
          <Image
            src={rightImage.url}
            alt={rightImage.altText || ''}
            layout="intrinsic"
            width={rightWidth}
            height={height}
            loader={imageLoaderResponsive(rightWidth, height)}
          />
          {rightImage.copyright &&
            <footer>
              <small className="copyright">
                &copy; {rightImage.copyright.replace('&copy;', '')}
              </small>
            </footer>}
        </div>
      </div>
      {caption && (
        <div className="images-caption" lang={language !== 'en' ? language : undefined}>
          {caption}
        </div>
      )}
    </div>
  </ImagesWrapper>;
}
